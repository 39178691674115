<template>
  <div class="coin" :class="['coin', selected?'item-select':'']" @click="clickHandler">
    <div>
      <div>
        <span class="number">{{ coinNum }}</span>
        <span class="desc">金币</span>
      </div>
      <div class="price-wrap">
        <span class="price">{{ currency }}{{ price }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selected: {
      type:Boolean,
      default:false
    },
    coinNum: {
      type:String, 
    },
    price: {
      type:String
    },
    currency: {
      type: String,
      default:'￥'
    }
    

  },
  data(){
    return {
      isSelected: false
    }
  },
  methods: {
    clickHandler(){
      this.$emit('select', '')
    }
  }
};
</script>

<style lang="scss" scoped>
.coin {
  @include flex-center;
  flex-direction: column;

  height: 100%;
  border: 1px solid #bcbcbc;
  border-radius: 4.2667vw;
  color: #bcbcbc;
  .number {
    font-size: 42px;
    font-weight: bold;
    margin-right: 10px;
  }
  .desc {
    font-size: 26px;
    font-weight: 300;
  }
  .price-wrap {
    .price {
      font-size: 24px;
      font-weight: bold;
    }
  }
}
.item-select {
  color: #FF4B6C;
  border-color: #FB5465;

}
</style>
