<template>
  <div class="charge-card" @click="clickHandler">
    <div :class="['item', cardInfo.className]">
      <img v-if="cardInfo.partnerIcon != ''"
        :src="cardInfo.partnerIcon"
        v-show="cardInfo.partnerIcon"
        class="partner-icon"
        alt=""
      />
      <div class="limit">
        <span>{{ limit }}</span>
        <span>{{ limitUnitText }}</span>
      </div>
      <div class="discount" v-if="discount">
        <span>{{ currency }}{{ discount }}</span>
        <span class="line-divide"></span>
      </div>
      <div class="price">
        <span>￥{{ price }}</span>
      </div>
      <img v-if="cardInfo.selectIcon != ''"
        :src="cardInfo.selectIcon"
        v-show="cardInfo.selectIcon"
        class="select-icon"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "ChargeCard",
  props: {
    select: {
      type: Boolean,
      default: false,
    },
    limit: {
      type: String,
    },
    limitUnitText: {
      type: String,
      default: "天",
    },
    discount: {
      type: String,
      default: "",
    },
    price: {
      type: String,
      default: "",
    },
    currency: {
      type: String,
      default: "￥",
    },
    hasPartnerIcon: {
      type: Boolean,
      default: false,
    },
    hasTasteVipIcon: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectIcon: require("@/assets/select.png"),
      partnerIcon: require("@/assets/icon-svip2.png"),
      partnerSelectIcon: require("@/assets/icon-svip.png"),
      tasteVipIcon: require("@/assets/icon-taste-vip2.png"),
      tasteVipSelectIcon: require("@/assets/icon-taste-vip.png"),
    };
  },
  computed: {
    cardInfo() {
      // 是否有 合伙人 的标签
      let partner = this.hasPartnerIcon ? this.partnerIcon : "";
      if (this.hasTasteVipIcon) {
        partner = this.tasteVipIcon;
      }

      let info = {
        partnerIcon: partner,
        selectIcon: "",
        className: "",
      };

      if (this.select) {
        info.partnerIcon = this.hasPartnerIcon ? this.partnerSelectIcon : "";
        if (this.hasTasteVipIcon) {
          info.partnerIcon = this.tasteVipSelectIcon;
        }
        info.selectIcon = this.selectIcon;
        info.className = "item-select";
      }
      return info;
    },
  },
  methods: {
    clickHandler() {
      this.$emit("select", "");
    },
  },
};
</script>

<style lang="scss" scoped>
.charge-card {
  font-family: PingFang SC;
  border-radius: 32px;
  .item {
    @include flex-center;
    flex-direction: column;
    box-sizing: border-box;
    border: 1px solid #3b3b3b;
    border-radius: 32px;
    font-weight: bold;
    justify-content: space-around;
    padding: 39px 0 43px 0;
    position: relative;
    height: 100%;

    .partner-icon {
      width: 86px;
      height: 30px;
      position: absolute;
      top: -15px;
    }

    .limit {
      color: rgba(59, 59, 59, 0.95);
      span:nth-child(1) {
        font-size: 48px;
      }
      span:nth-child(2) {
        font-size: 26px;
        color: #b8b8b8;
      }
    }
    .price {
      background: #fafafa;
      padding: 12px 13px;
      font-size: 36px;
      font-weight: bold;
      color: #3b3b3b;
      line-height: 36px;
      border-radius: 12px;
    }
  }
  .discount {
    padding: 6px 0;
    font-size: 26px;
    font-weight: 300;
    color: #b9b9b9;
    position: relative;

    .line-divide {
      position: absolute;
      display: inline-block;
      height: 1px;
      width: 100%;
      background: #b9b9b9;
      transform: rotate(5deg);
      left: 4px;
      top: 22px;
    }
  }
  .item-select {
    border: 1px solid #FB5465;
    position: relative;
    .limit {
      color: #FB5465;
    }
    .price {
      color: #FB5465;
    }
    .select-icon {
      position: absolute;
      bottom: -20px;
      width: 40px;
      height: 40px;
    }
  }
}
</style>
