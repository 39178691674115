<template>
  <div id="app">
    <recharge-center></recharge-center>
  </div>
</template>

<script>
import RechargeCenter from './recharge-center.vue';

export default {
  name: 'App',
  components: {
    RechargeCenter
  }
}
</script>

<style>
html,body {
  padding: 0;
  margin: 0;
  font-family: PingFang SC;
}
*{
  -webkit-tap-highlight-color:transparent;
}
</style>

<style lang="scss" scoped>

@import './style.scss';
</style>