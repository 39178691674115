import WxmpRsa from "wxmp-rsa";

// 定义公钥
const publicKey = `
-----BEGIN PUBLIC KEY-----\nMIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBg
QCjJ8VbhSaNQtnZKrTQS43zYHVzRhfUH516Y9QNnhNHfsuhMoCT22OX1/YxcV
Qj+npY5rI/TK5/aZ0OMfrhuqrNCrXInbRG+P9o9C0AmOqIL28SlST68XCXa
443vk2p3JZBO0v1JR6RwuXSwxoCuQFnFi0DhuNMdsZtvQCXhD+KVQIDAQAB\n-----END PUBLIC KEY-----
`;

function getRsaCode(str) {
  const rsa = new WxmpRsa();
  rsa.setPublicKey(publicKey);
  const data = rsa.encryptLong(str);
  return data;
}

export default {
  getRsaCode,
};
