import axios from "axios";
import rsa from "./rsa.js";

const rsatime = rsa.getRsaCode(JSON.stringify("jd&" + Date.now()));
const instance = axios.create({
  url: "/api",
  timeout: 80000,
  headers: {
    token: rsatime,
    "mask-request-source": "h5",
    "Content-Type": "application/x-www-form-urlencoded",
  },
});

instance.interceptors.response.use(function (res) {
  // console.log(res);
  if (res.config.url === "/api/mp/user/info") {
    return res.data;
  } else if (res.data.errorCode === 0) {
    return res.data.data;
  } else {
    console.log(res.data.errorMsg);
    alert(res.data.errorMsg);
    return [];
  }
});
// instance.interceptors.request();

export function getLoginInfo(params) {
  return instance.get("/api/mp/oauth/login", { params });
}

export function getCode(params) {
  return instance.get("/api/mp/oauth/redirect_url", { params });
}
export function getUserInfo(params) {
  return instance.get("/api/mp/user/info", { params });
}

export function getRechargeOption(params) {
  return instance.get("/api/mp/pay/recharge_option", { params });
}

export function CreateOrder(data) {
  return instance.post("/api/mp/pay/create_order", data);
}

export function OrderStatus(params) {
  return instance.get("/api/mp/pay/order_status", params);
}

export function cancelOrder(data) {
  return instance.post("/api/mp/pay/cancel_order", data);
}

export function getOrderStatus(params) {
  return instance.get("/api/mp/pay/order_status", { params });
}
