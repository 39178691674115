<template>
  <div class="overlay" v-if="isShow">
    <div class="dialog">
      <div class="title">{{ title }}</div>
      <div class="btn" @click="close">确认</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OverLay",
  props: {
    value: { type: Boolean, default: false },
    title: {type: String, default:"该手机号不存在，请输入正确手机号"}
  },
  data() {
    return {
      isShow: this.value,
    };
  },
  watch:{
    value(val){
      this.isShow = val
    }

  },
  methods: {
    
    close() {
      this.isShow = false;
      this.$emit('input', false)
    },
  },
};
</script>

<style lang="scss" scoped>
.overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999;

  .dialog {
    @include flex-center;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 18px;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 598px;
    height: 255px;

    .title {
      font-size: 28px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #000000;
      line-height: 36px;
      margin-bottom: 45px;
    }

    .btn {
      @include flex-center;
      width: 259px;
      height: 61px;
      color: #ffffff;
      font-weight: bold;
      background: #f42b72;
      border-radius: 22px;
    }
  }
}
</style>
